import React, { useState, useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import { useQueryParam, StringParam } from "use-query-params"

import Layout from "../components/layout"

const Query = () => {
  const intl = useIntl()

  const [q, setQ] = useQueryParam("q", StringParam)
  const [resultCount, setResultCount] = useState(0)
  const [resultArr, setResultArr] = useState([])

  let svgItems = []

  function fetchSvgItems() {
    fetch("/data/svgItems.json")
      .then(response => response.json())
      .then(data => {
        svgItems = data
        const result = search(q)
        setResultArr(result)
        setResultCount(result.length)
      })
  }

  function search(word) {
    const arr = []
    for (const k in svgItems) {
      if (svgItems[k].tag.indexOf(word.toLowerCase()) !== -1) {
        arr.push(svgItems[k])
      }
    }
    return arr
  }

  useEffect(() => {
    if (q == null || q === "") {
      setQ("")
    } else {
      fetchSvgItems()
    }
  }, [])

  function setUrl(item) {
    if (item.cat === "kung-fu-geek") {
      return "/illustrations/kung-fu-download?q=" + item.name
    } else {
      return "/" + item.cat + "/download?q=" + item.name
    }
  }

  function setClassName(item) {
    if (item.cat === "kung-fu-geek") {
      return "p-2 w-full h-32"
    } else if (item.cat === "icons") {
      return "p-2 w-full h-full"
    } else {
      return "w-full h-full"
    }
  }

  return (
    <Layout>
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "query.result" })}({q}):
          <span className="font-bold">{" " + resultCount + " "}</span>
          {intl.formatMessage({ id: "query.items" })}
        </h3>
        <div className="main-images mb-8">
          <div className="images grid grid-cols-3 gap-2 sm:grid-cols-5 md:grid-cols-6 md:gap-3">
            {resultArr.map((item, index) => (
              <div
                key={index}
                className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow cursor-pointer"
              >
                <Link to={"/" + item.cat + "/download?q=" + item.name}>
                  <img
                    src={"/images/" + item.cat + "/" + item.name + ".svg"}
                    alt={item.tag}
                    className={setClassName(item)}
                    loading="lazy"
                  ></img>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Query
